import { SanityDividerPhoto } from '@data/sanity/queries/types/modules'

import Photo from '@components/photo'

type DividerPhotoProps = SanityDividerPhoto

const DividerPhoto = ({ photo }: DividerPhotoProps) => (
  <div>
    <Photo image={photo} layout="responsive" />
  </div>
)

export default DividerPhoto
